import { themeColors } from "@/app/theme";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewOrderBook from "../NewOrderBook";
import { InstrumentDTO, useGetInstrumentsQuery } from "@/redux/instrumentsApiSlice";
import { useAppDispatch } from "@/store/hooks";
import { setInstruments } from "@/redux/instrumentSlice";
import NewParentOrdersList from "../NewParentOrdersList";
import NewSummaryBox from "../NewSummaryBox";
import AppBar from "@/app/router/components/AppBar";
import { refetchTimeouts } from "@/utils/Constants";
import { useGetParentOrdersQuery } from "@/redux/parentApiSlice";
import { setParentOrders } from "@/redux/parentSlice";

const NewDashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data: instruments } = useGetInstrumentsQuery();
  const [usedInstruments, setUsedInstruments] = useState<InstrumentDTO[]>([]);
  const { currentData: parentOrders } = useGetParentOrdersQuery(undefined, {
    pollingInterval: refetchTimeouts.fast,
  });

  useEffect(() => {
    dispatch(setInstruments(instruments?.length ? instruments : []));
  }, [instruments]);

  useEffect(() => {
    dispatch(setParentOrders(parentOrders || []));
  }, [parentOrders]);

  useEffect(() => {
    const usedInstrumentsIds = [...new Set(parentOrders?.map((order) => order.instrument_id))];
    setUsedInstruments(
      instruments?.filter((instrument) => usedInstrumentsIds.includes(instrument.instrument_id)) || []
    );
  }, [instruments, parentOrders]);

  const moveOrderbookToStart = (instrumentId: string) => {
    const instrumentToMove = usedInstruments.find((instrument) => instrument.instrument_id === instrumentId);
    if (!instrumentToMove) return;

    setUsedInstruments([
      instrumentToMove,
      ...usedInstruments.filter((instrument) => instrument.instrument_id !== instrumentId),
    ]);
  };

  return (
    <Stack>
      <AppBar dashboard={false} />

      <Stack mt={12}>
        <Stack
          spacing={2}
          sx={{
            padding: "14px 48px 12px 48px",
          }}
        >
          <Stack
            sx={{
              paddingTop: "14px",
              paddingBottom: "37px",
              backgroundColor: themeColors.white.secondary,
              marginBottom: "12px",
              minHeight: 570,
            }}
            spacing={1}
          >
            <Typography color={themeColors.black.secondary} variant="h4">
              Active orders
            </Typography>
            <Stack gap={2} flexDirection="row">
              <NewParentOrdersList moveOrderbookToStart={moveOrderbookToStart} parentOrders={parentOrders} />

              <Stack
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none", // for Chrome, Safari, and newer versions of Edge
                  },
                  "scrollbarWidth": "none", // for Firefox
                }}
                flex={3}
                gap={1.5}
                flexDirection="row"
                overflow="auto"
              >
                {usedInstruments.map((instrument) => (
                  <NewOrderBook instrument={instrument} />
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Box sx={{ height: "1px", backgroundColor: themeColors.border.grey }} />
        </Stack>
        <NewSummaryBox />
      </Stack>
    </Stack>
  );
};

export default NewDashboard;
