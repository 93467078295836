import { themeColors } from "@/app/theme";
import useTimer from "@/hooks/useTimer";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import {
  Notification,
  getNotificationBody,
  getNotificationSender,
  getNotificationSubtitle,
  getNotificationTitle,
  getRfqDirection,
  isMatchRequest,
  isRfq,
  setActiveNotification,
  setActiveRfq,
} from "@/redux/notificationSlice";
import { setParentOrder } from "@/redux/parentSlice";
import { setPrice } from "@/redux/pricesSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import useNotificationMutations from "./useNotificationMutations";

export interface DetailsProps {
  notification: Notification;
  handleClose: () => void;
}

export default function NotifiactionDetails(props: DetailsProps) {
  const dispatch = useAppDispatch();
  const { handleAcceptMatch, isLoadingAccept, error } = useNotificationMutations();
  const instruments = useAppSelector(selectInstruments);
  const { timer, isTimerDone } = useTimer(props.notification !== null ? props.notification.expires_in_seconds : 0);

  const residual = numericFormatter(
    (isMatchRequest(props.notification) ? props.notification.residual_quantity : 0).toString(),
    {
      thousandSeparator: true,
    }
  );

  const instrument = instruments.find((i) => i.instrument_id === props.notification?.instrument_id);
  const handleAcceptAction = () => {
    if (isMatchRequest(props.notification)) {
      handleAcceptMatch(props.notification);
      props.handleClose();
    }
    if (isRfq(props.notification)) {
      // Deslect any parent order.
      dispatch(setParentOrder(null));

      // Reset the selected price.
      // Incompatible values between instruments will cause issues if this isn't done.
      dispatch(setPrice(null));

      // Inform the app about the RFQ details.
      dispatch(setActiveRfq(props.notification));

      // SummaryBox needs to know the instrument as it usually gets this info
      // from the parent order.
      const instrument = instruments.find((i) => i.instrument_id === props.notification?.instrument_id);
      if (instrument) {
        dispatch(setInstrument(instrument));
      }
      props.handleClose();
    }
  };

  return (
    <Stack
      sx={{
        px: 3,
        pt: 1,
        backgroundColor: themeColors.white.primary,
        borderTop: 1,
        borderBottom: 1,
        borderColor: themeColors.border.grey,
      }}
    >
      {/* Client info */}
      <Box display="flex" justifyContent="flex-end">
        <Typography variant="body" color={themeColors.text.secondary}>
          {getNotificationSender(props.notification)}
        </Typography>
      </Box>

      {/* Badge & Title */}
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <CircleIcon color="success" sx={{ fontSize: 12 }} />
        <Typography variant="h3">{getNotificationTitle(props.notification, instrument)}</Typography>
      </Stack>

      {/* Info text */}
      <Typography ml={2} variant="h5Light" color={themeColors.text.secondary}>
        {getNotificationSubtitle(props.notification, instrument)}
      </Typography>

      <Stack spacing={1} sx={{ mt: 2 }}>
        {/* Match info */}
        <Grid container sx={{ pb: 1 }}>
          <Grid size={4}>
            <Stack>
              <Typography variant="body" textAlign="center" color={themeColors.text.secondary}>
                {isMatchRequest(props.notification) ? "Order details" : "Direction"}
              </Typography>
              <Typography textAlign="center" variant="h4">
                {isMatchRequest(props.notification)
                  ? getNotificationBody(props.notification, instrument)
                  : getRfqDirection(props.notification)}
              </Typography>
            </Stack>
          </Grid>
          <Grid size={4}>
            <Stack>
              <Typography variant="body" textAlign="center" color={themeColors.text.secondary}>
                {isMatchRequest(props.notification) ? "Residual" : "Total quantity"}
              </Typography>
              <Typography textAlign="center" variant="h4">
                {isMatchRequest(props.notification)
                  ? residual
                  : numericFormatter((props.notification?.quantity || 0).toString(), {
                      thousandSeparator: true,
                    })}
              </Typography>
              {isRfq(props.notification) && (
                <Typography variant="caption" textAlign="center">
                  You can edit this amount when trading
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid size={4}>
            <Stack>
              <Typography variant="body" textAlign="center" color={themeColors.text.secondary}>
                {isMatchRequest(props.notification) ? "Time Remaining" : "Time to action"}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="center">
                <AccessTimeIcon color="error" sx={{ fontSize: 14 }} fontWeight={500} />
                <Typography
                  color="error"
                  variant="h4"
                  // Fixed timer width stops the clock from jumping around.
                  // If the string's length is 5 === hh:mm format.
                  width={timer.length === 5 ? 48 : 76}
                >
                  {timer}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Stack direction="row" justifyContent="center" flex={1} spacing={2}>
          <Button
            sx={{
              color: themeColors.black.secondary,
              fontSize: 14,
              borderRadius: 2,
            }}
            onClick={() => dispatch(setActiveNotification(null))}
          >
            Dismiss
          </Button>
          <LoadingButton
            id="accept-notification"
            loading={isLoadingAccept}
            disabled={isTimerDone}
            variant="contained"
            color="success"
            onClick={() => handleAcceptAction()}
            sx={{ fontSize: 18, borderRadius: 2 }}
          >
            <span>{isRfq(props.notification) ? "Respond" : "Accept"}</span>
          </LoadingButton>
        </Stack>
        <Typography variant="caption" mt={!!error ? 1 : 0} color="error">
          {error}
        </Typography>
      </Stack>
    </Stack>
  );
}
