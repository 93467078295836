import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { Price, PriceInfo } from "@/redux/pricesSlice";
import mockedLivePrice from "../OrderBook/mockedLivePrice";
import { OrderDTO } from "@/redux/ordersApiSlice";
import { Direction } from "@/redux/parentApiSlice";

export const formatInThousands = (value: number, digitsAfterComma = 2) => {
  const thousands = value / 1000;
  return `${Number.isInteger(thousands) ? thousands : thousands.toFixed(digitsAfterComma)}k`;
};

export const calculatePercentage = (value: number, maxValue: number) => (value / maxValue) * 100;

export const getPrices = (instrument: InstrumentDTO | null): PriceInfo => {
  if (!instrument?.tick_size) {
    return { prices: [], floor: 0, ceiling: 0, step: 0 };
  }

  const { ceiling, floor } = mockedLivePrice(instrument);
  const tickSize = instrument.tick_size;

  const prices = Array.from({ length: 11 }, (_, index) => {
    const value = ceiling - index * tickSize;

    return { value, isElectronic: value === ceiling || value === floor };
  });

  return { prices, floor, ceiling, step: tickSize };
};

export const generateOrdersForChart = (prices: Price[], orders: OrderDTO[], userId: string) => {
  return prices.map((price) => {
    const priceValue = price.value;
    const foundOrders = orders.filter((order) => order.limit_price === priceValue) || [];

    const initialOrderState = {
      ...price,
      buyQuantity: 0,
      sellQuantity: 0,
      myBuyQuantity: 0,
      mySellQuantity: 0,
    };

    if (!foundOrders.length) return initialOrderState;

    return foundOrders.reduce((acc, order) => {
      const isBuyOrder = order.direction === Direction.BUY;
      const isUserOrder = order.created_by === userId;
      const quantity = order.quantity;

      if (isBuyOrder) {
        acc.buyQuantity += quantity;
        if (isUserOrder) acc.myBuyQuantity += quantity;
      } else {
        acc.sellQuantity += quantity;
        if (isUserOrder) acc.mySellQuantity += quantity;
      }

      return acc;
    }, initialOrderState);
  });
};
