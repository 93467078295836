import { themeColors } from "@/app/theme";
import TickerCircle from "@/components/TickerCircle";
import { metricFormatter } from "@/utils/String";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const formatQuantity = (quantity: number): string => {
  return quantity < 1000 ? quantity.toString().padStart(3, "0") : metricFormatter(quantity, 1);
};

export const formatPrice = (price: number): string => {
  return price === 0 ? "00000" : price.toFixed(3);
};

interface PriceInfoCardProps {
  title: string;
  quantity: number;
  price: number;
  color: string;
}

const PriceInfoCard: React.FC<PriceInfoCardProps> = ({ title, quantity, price, color }) => {
  return (
    <Stack spacing={-0.5} sx={{ width: 80, alignItems: "center" }}>
      <Typography color={themeColors.black.primary} variant="h5">
        {title}
      </Typography>
      <Typography color={themeColors.black.primary} variant="h2Light">
        {formatQuantity(quantity)}
      </Typography>
      <Typography color={color} variant="h5">
        <TickerCircle fillColor={color} height={7} />
        {formatPrice(price)}
      </Typography>
    </Stack>
  );
};

export default PriceInfoCard;
