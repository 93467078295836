import { themeColors, themeVariants } from "@/app/theme";
import ErrorCard from "@/components/ErrorCard";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { refetchTimeouts } from "@/utils/Constants";
import { ParentOrderDTO, ParentOrderState, useGetParentOrderQuery } from "@/redux/parentApiSlice";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";
import { useSelector } from "react-redux";
import CancelParentOrder from "./CancelParentOrder";
import { useGetMyOrdersQuery } from "@/redux/ordersApiSlice";
import SmallParentCard from "./SmallParentCard";
import LargeParentCard from "./LargeParentCard";
import { selectParent, setParentOrder } from "@/redux/parentSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

export interface NewParentOrderCardProps {
  order: ParentOrderDTO;
  moveOrderbookToStart: (instrumentId: string) => void;
}

export default function NewParentOrderCard({ order, moveOrderbookToStart }: NewParentOrderCardProps) {
  const dispatch = useAppDispatch();
  const selectedParentOrder = useAppSelector(selectParent);
  const { isXs } = useBreakpoint();
  const instruments = useSelector(selectInstruments);

  const [isCancel, setIsCancel] = useState(false);

  const {
    currentData: myOrders,
    error,
    isError,
    isLoading,
    refetch,
  } = useGetMyOrdersQuery(order?.parent_order_id || "", {
    skip: !order,
    pollingInterval: refetchTimeouts.fast,
  });
  const { currentData: parentOrderResponse, isSuccess } = useGetParentOrderQuery(order.parent_order_id, {
    pollingInterval: refetchTimeouts.fast,
  });

  const instrument = instruments.find((instrument) => order.instrument_id === instrument.instrument_id);
  const isActive = order.parent_order_id == selectedParentOrder?.parent_order_id;
  const isDisabled = order.state === ParentOrderState.Filled;

  const handleCancelOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsCancel(true);
    dispatch(setParentOrder(null));
  };

  const onParentOrderClick = () => {
    if (isDisabled) return;

    moveOrderbookToStart(instrument?.instrument_id || "");
    dispatch(setParentOrder(order));
    dispatch(setInstrument(instrument || null));
  };

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading || !instrument) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  const getThemeStyles = () => {
    if (isDisabled) return { ...themeVariants.disabled, borderBottom: `0.5px solid ${themeColors.black.secondary}` };
    if (isActive) return themeVariants.selected;
    return themeVariants.unselected;
  };

  if (isSuccess && parentOrderResponse) {
    return (
      <Paper
        data-testid={`parent-order-${order.parent_order_id}`}
        elevation={0}
        sx={{
          borderRadius: "6px",
          padding: "6px 10px",
          border: isDisabled ? "" : `0.5px solid ${themeColors.black.secondary}`,
          transitionDuration: 400,
          transitionTimingFunction: "ease",
          transitionProperty: "all",
          cursor: isDisabled ? "auto" : "pointer",
          ...getThemeStyles(),
        }}
        onClick={onParentOrderClick}
      >
        {isCancel ? (
          <CancelParentOrder handleClose={() => setIsCancel(false)} id={order.parent_order_id} />
        ) : isXs ? (
          <SmallParentCard instrument={instrument} parentOrderResponse={parentOrderResponse} />
        ) : (
          <LargeParentCard
            instrument={instrument}
            parentOrderResponse={parentOrderResponse}
            myOrders={myOrders || []}
            handleCancelOrder={handleCancelOrder}
            isActive={isActive}
            isDisabled={isDisabled}
          />
        )}
      </Paper>
    );
  }

  return null;
}
