import AppBar from "@/app/router/components/AppBar";
import { selectIsUserIntickAdmin } from "@/redux/authSlice";
import { useGetCompaniesQuery } from "@/redux/companiesApiSlice";
import { useAppSelector } from "@/store/hooks";
import Stack from "@mui/material/Stack";
import CompaniesTable from "./CompaniesTable";
import CreateCompanyModal from "./CreateCompanyModal";

export default function CreateCompany() {
  const { data } = useGetCompaniesQuery();
  const isIntickAdmin = useAppSelector(selectIsUserIntickAdmin);

  if (!data || !isIntickAdmin) {
    return null;
  }
  return (
    <Stack spacing={13} width="100%" alignItems="center" flexDirection="column">
      <AppBar dashboard={false} />
      <Stack sx={{ py: 3, px: 3 }} width="100%" alignItems="center" spacing={3}>
        <CreateCompanyModal data={data} />
        <CompaniesTable data={data} />
      </Stack>
    </Stack>
  );
}
