import React, { useEffect, useState } from "react";
import { themeColors } from "@/app/theme";
import { selectInstrument, setInstrument, setMarketData } from "@/redux/instrumentSlice";
import { Direction } from "@/redux/parentApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Box, Stack, Typography } from "@mui/material";
import HorizontalBarChart from "./HorizontalBarChart";
import OrderBookHeader from "./OrderBookHeader";

import { setPrice } from "@/redux/pricesSlice";
import { InstrumentDTO, useGetMarketDataQuery } from "@/redux/instrumentsApiSlice";
import { selectAuthUser } from "@/redux/authSlice";
import { getDecimalScale } from "../Summary/LimitPriceInput";
import { NumericFormat } from "react-number-format";
import { generateOrdersForChart, getPrices } from "./helpers";
import { useGetOrdersByInstrumentQuery } from "@/redux/ordersApiSlice";
import { refetchTimeouts } from "@/utils/Constants";
import { selectParentOrders, setParentOrder } from "@/redux/parentSlice";

type Order = {
  buyQuantity: number;
  sellQuantity: number;
  mySellQuantity: number;
  myBuyQuantity: number;
  value: number;
  isElectronic: boolean;
};

interface NewOrderBookProps {
  instrument: InstrumentDTO;
}

const NewOrderBook: React.FC<NewOrderBookProps> = ({ instrument }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthUser);
  const parentOrders = useAppSelector(selectParentOrders);
  const selectedInstrument = useAppSelector(selectInstrument);
  const decimalScale = getDecimalScale(instrument.tick_size);
  // @TODO Market data should be fetched outside of this component when endpoint is ready
  const { data: marketData } = useGetMarketDataQuery(instrument.instrument_id, { skip: !instrument });
  const { currentData: ordersData } = useGetOrdersByInstrumentQuery(instrument.instrument_id, {
    pollingInterval: refetchTimeouts.fast,
    skip: !instrument,
  });

  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (marketData) {
      dispatch(setMarketData(marketData));
    }
  }, [marketData]);

  useEffect(() => {
    const priceInfo = getPrices(instrument);
    setOrders(generateOrdersForChart(priceInfo.prices, ordersData || [], user?.user_id || ""));
  }, [ordersData, instrument]);

  const ordersMaxQuantity = Math.max(...orders.flatMap((order) => [order.buyQuantity, order.sellQuantity])) || 1;

  const renderPrice = (price: number, isElectronic = false) => {
    const rowHoverStyles = {
      ".liquidity-row:hover &": {
        textDecoration: "underline",
        textUnderlineOffset: "3px",
      },
    };
    return (
      <NumericFormat
        value={price}
        allowNegative
        fixedDecimalScale
        decimalScale={decimalScale}
        displayType="text"
        renderText={(value) => (
          <Box
            sx={{
              backgroundColor: "white",
              borderRight: `0.2px solid ${themeColors.text.disabled}`,
              borderLeft: `0.2px solid ${themeColors.text.disabled}`,
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                lineHeight: "140%",
                letterSpacing: "-0.3px",
                fontSize: "16px",
                ...(isElectronic ? {} : rowHoverStyles),
              }}
              color={isElectronic ? themeColors.text.disabled : themeColors.black.secondary}
            >
              {value}
            </Typography>
          </Box>
        )}
      />
    );
  };

  const onRowClick = (price: number) => {
    if (selectedInstrument?.instrument_id !== instrument.instrument_id) {
      const parentToSelect = parentOrders
        .filter((parent) => parent.instrument_id === instrument.instrument_id)
        .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())[0];
      dispatch(setParentOrder(parentToSelect || null));
    }
    dispatch(setPrice(price));
    dispatch(setInstrument(instrument));
  };

  return (
    <Box
      sx={{
        paddingTop: "4px",
        backgroundColor: themeColors.white.quaternary,
        border: `1px solid ${themeColors.border.lightGrey}`,
        boxShadow: "1px 1px 5px 0px rgba(255, 255, 255, 0.80) inset",
      }}
    >
      {marketData && (
        <OrderBookHeader
          bestAsk={marketData.best_sell_price || 0}
          bestBid={marketData.best_buy_price || 0}
          total={marketData.total_buy_quantity + marketData.total_sell_quantity}
          selectedInstrument={instrument}
        />
      )}

      <Stack
        sx={{ padding: "29px 4px 24px 4px", maxWidth: 440 }}
        mt={0.5}
        spacing={3}
        direction="column"
        alignItems="center"
      >
        {orders.map((order, index) => (
          <Stack
            onClick={() => {
              if (!order.isElectronic) {
                onRowClick(order.value);
              }
            }}
            className="liquidity-row"
            sx={{
              cursor: order.isElectronic ? "auto" : "pointer",
              ...(order.buyQuantity || order.sellQuantity
                ? {
                    "&:hover": {
                      boxShadow: "0px -2px 2px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                    },
                  }
                : {}),
            }}
            key={index}
            alignItems="center"
            direction="row"
          >
            {order.isElectronic ? (
              renderPrice(order.value, order.isElectronic)
            ) : (
              <>
                <HorizontalBarChart
                  maxOrdersValue={ordersMaxQuantity}
                  value={order.buyQuantity}
                  chipValue={order.myBuyQuantity}
                  variant={Direction.BUY}
                />
                {renderPrice(order.value)}
                <HorizontalBarChart
                  maxOrdersValue={ordersMaxQuantity}
                  chipValue={order.mySellQuantity}
                  value={order.sellQuantity}
                  variant={Direction.SELL}
                />
              </>
            )}
          </Stack>
        ))}
      </Stack>
      <Box sx={{ backgroundColor: themeColors.white.primary, padding: "14px 0" }} />
    </Box>
  );
};

export default NewOrderBook;
