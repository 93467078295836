import { themeColors } from "@/app/theme";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { selectParent } from "@/redux/parentSlice";
import { useAppSelector } from "@/store/hooks";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { capitalize } from "lodash";

const formatNumber = (number: number) => {
  return number.toLocaleString("en-US");
};

interface SummaryBoxFooterProps {
  instrument: InstrumentDTO;
  total: number;
  limitPrice: number | null;
  submitButtonLoading?: boolean;
  error: string | null;
}

const SummaryBoxFooter: React.FC<SummaryBoxFooterProps> = ({
  instrument,
  total,
  limitPrice,
  submitButtonLoading,
  error,
}) => {
  const selectedParentOrder = useAppSelector(selectParent);

  return (
    <Stack
      sx={{
        padding: "22px 48px 12px 24px",
        borderTop: `1px solid ${themeColors.border.grey}`,
        position: "fixed",
        bottom: "15px",
        width: "inherit",
        backgroundColor: error ? themeColors.error.secondary : "",
      }}
      spacing={1}
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack>
          <Typography color={themeColors.black.secondary} variant="body">
            Total:
          </Typography>
          <Typography color={themeColors.black.primary} variant="h4">
            {formatNumber(total)}
          </Typography>
        </Stack>
        <LoadingButton
          size="small"
          variant="contained"
          color={selectedParentOrder?.direction}
          sx={{ fontSize: 18, borderRadius: "10px", width: 110 }}
          data-testid="submit-order"
          type="submit"
          disabled={!limitPrice || !!error}
          loading={submitButtonLoading}
        >
          <Typography color={themeColors.white.primary} variant="h4">
            {capitalize(selectedParentOrder?.direction)}
          </Typography>
        </LoadingButton>
      </Stack>
      {error ? (
        <Typography variant="caption" color={themeColors.error.primary}>
          {error}
        </Typography>
      ) : null}
      <Stack>
        <Typography variant="caption" color={themeColors.black.primary}>
          • Please see your limit price for this trade.
        </Typography>
        <Typography variant="caption" color={themeColors.black.primary}>
          • The minimum block size for this instrument is {instrument.min_quantity}.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SummaryBoxFooter;
