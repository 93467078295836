import { themeColors } from "@/app/theme";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { Direction, PostParentOrderPayload, useCreateParentOrderMutation } from "@/redux/parentApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { createSchema, defaultValues, FormData } from "./schema";
import { setParentOrder } from "@/redux/parentSlice";
import { setPrice } from "@/redux/pricesSlice";

interface Props {
  setOpen: (open: boolean) => void;
  open: boolean;
}

const findInstrument = (instruments: InstrumentDTO[], instrumentId: string) =>
  instruments?.find((i) => i.instrument_id === instrumentId) || null;

const CreateOrderModal = ({ setOpen, open }: Props) => {
  const dispatch = useAppDispatch();

  const instruments = useAppSelector(selectInstruments);
  const [minBlockSize, setMinBlockSize] = useState(0);

  const schema = useMemo(() => createSchema(minBlockSize), [minBlockSize]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
    reValidateMode: "onChange",
  });

  const formValues = watch();
  const { direction, instrument_id, quantity } = formValues;
  const instrument = findInstrument(instruments, instrument_id);

  const allFieldsFilled = Object.values(formValues).every((value) => !!value);

  const [createOrder, { error: submitError, isLoading: isLoadingSubmit, isError: isErrorSubmit }] =
    useCreateParentOrderMutation();

  const handleChangeInstrument = (event: SelectChangeEvent) => {
    const currentInstrument = findInstrument(instruments, event.target.value);
    if (!currentInstrument) return;
    setMinBlockSize(currentInstrument.min_quantity);
  };

  const onSubmit = async () => {
    const payload: PostParentOrderPayload = {
      direction: direction as Direction,
      instrument_id,
      quantity,
    };
    try {
      const response = await createOrder(payload).unwrap();
      dispatch(setParentOrder(response));
      dispatch(setPrice(null));
      const instrument = instruments.find((x) => x.instrument_id === response.instrument_id);
      if (instrument) {
        dispatch(setInstrument(instrument));
      }

      handleClose();
    } catch (error) {
      console.log("[ AddOrderBtn ] handleSubmit ERROR: ", error);
    }
  };

  const InstrumentsInput = () => {
    if (!instruments.length) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 1,
            minWidth: 256,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <FormControl sx={{ width: 250 }}>
        <InputLabel
          id="select-instrument"
          shrink
          sx={{
            "&.Mui-focused": { color: themeColors.active.primary },
          }}
        >
          Instrument
        </InputLabel>
        <Controller
          name="instrument_id"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              labelId="select-instrument"
              id="instrument"
              label="Instrument"
              onChange={(e) => {
                field.onChange(e);
                handleChangeInstrument(e);
              }}
              size="small"
              displayEmpty
              renderValue={(value) =>
                value === "" ? (
                  <Typography color={themeColors.text.disabled}>Please select</Typography>
                ) : (
                  `${instrument?.name} ${instrument?.term}`
                )
              }
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: themeColors.active.primary,
                },
                ".MuiSelect-icon": {
                  color: instrument === null ? themeColors.text.disabled : null,
                },
              }}
            >
              {instruments.map((instrument, i) => (
                <MenuItem key={i} value={instrument.instrument_id}>
                  {instrument.name} {instrument.term}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    );
  };

  const ErrorMessage = () => {
    if (isErrorSubmit && submitError && "data" in submitError) {
      return (
        <Alert severity="error" sx={{ flex: 1 }}>
          {`[${submitError.status}]: ${JSON.stringify(submitError.data)}`}
        </Alert>
      );
    }
    return null;
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { maxWidth: "none" },
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle sx={{ pb: 0 }}>
        <Typography variant="h2Light" color={themeColors.black.secondary}>
          Create order
        </Typography>
        <Typography variant="bodyLight" display="block" color={themeColors.black.secondary}>
          Please complete the fields below.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 12,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <Stack direction="row" spacing={2} mt={2}>
          <FormControl sx={{ width: 152 }}>
            <InputLabel
              id="select-direction"
              shrink
              sx={{
                "&.Mui-focused": { color: themeColors.active.primary },
              }}
            >
              Direction
            </InputLabel>
            <Controller
              name="direction"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="select-direction"
                  id="direction"
                  label="Direction"
                  size="small"
                  displayEmpty
                  renderValue={(value) =>
                    (value as string) === "" ? (
                      <Typography color={themeColors.text.disabled}>Please select</Typography>
                    ) : (
                      capitalize(value)
                    )
                  }
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeColors.active.primary,
                    },
                    ".MuiSelect-icon": {
                      color: direction === "" ? themeColors.text.disabled : null,
                    },
                  }}
                >
                  <MenuItem value={Direction.BUY}>Buy</MenuItem>
                  <MenuItem value={Direction.SELL}>Sell</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <InstrumentsInput />
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <NumericFormat
                onValueChange={(values) => {
                  field.onChange(values.floatValue ?? 0);
                }}
                thousandSeparator
                customInput={TextField}
                size="small"
                label="Quantity"
                disabled={!instrument_id}
                id="quantity"
                helperText={errors.quantity?.message}
                error={!!errors.quantity}
                sx={{
                  "width": 164,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: !!errors.quantity ? themeColors.error.primary : themeColors.active.primary,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: !!errors.quantity ? themeColors.error.primary : themeColors.active.primary,
                  },
                  "& .MuiFormHelperText-root": {
                    color: !!errors.quantity ? themeColors.error.primary : "transparent",
                  },
                }}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ErrorMessage />
        <LoadingButton
          loading={isLoadingSubmit}
          disabled={!allFieldsFilled}
          type="submit"
          variant="contained"
          data-testid="submit-parent-order"
          sx={{ mr: 2, mb: 3, mt: 1, borderRadius: 2, fontSize: 18 }}
        >
          <span>Done</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderModal;
