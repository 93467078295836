import { CompanyDTO } from "@/redux/companiesApiSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Form from "./Form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

interface Props {
  data: CompanyDTO[];
}

export default function CreateCompanyModal({ data }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ alignSelf: "flex-start" }}>
      <Button
        size="large"
        variant="contained"
        type="button"
        sx={{ mt: 2, mr: 2, borderRadius: 2 }}
        onClick={handleOpen}
      >
        Create a company
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Form companies={data} closeModal={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}
