import { baseApi } from "@/config/BaseQuery";
import { Direction } from "./parentApiSlice";

export enum OrderState {
  Working = "WORKING",
  AwaitingMatch = "AWAITING_MATCH",
  Matched = "MATCHED",
  AwaitingRfqAcceptance = "AWAITING_RFQ_ACCEPTANCE",
  PartialBrokerAccepted = "PARTIAL_BROKER_ACCEPTED",
  BrokerAccepted = "BROKER_ACCEPTED",
  PartialBrokerFilled = "PARTIAL_BROKER_FILLED",
  BrokerFilled = "BROKER_FILLED",
  Error = "ERROR",
  Cancelled = "CANCELLED",
  Rejected = "REJECTED",
  Expired = "EXPIRED",
}

export const orderStateLabels: Record<OrderState, string> = {
  [OrderState.Working]: "Working",
  [OrderState.AwaitingMatch]: "Awaiting Match",
  [OrderState.AwaitingRfqAcceptance]: "Awaiting RFQ Acceptance",
  [OrderState.Matched]: "Matched",
  [OrderState.PartialBrokerAccepted]: "Partially Broker Accepted",
  [OrderState.BrokerAccepted]: "Broker Accepted",
  [OrderState.PartialBrokerFilled]: "Partially Broker Filled",
  [OrderState.BrokerFilled]: "Broker Filled",
  [OrderState.Error]: "Error",
  [OrderState.Cancelled]: "Cancelled",
  [OrderState.Rejected]: "Rejected",
  [OrderState.Expired]: "Expired",
};

export const matureStates = [
  OrderState.Matched,
  OrderState.PartialBrokerAccepted,
  OrderState.BrokerAccepted,
  OrderState.PartialBrokerFilled,
  OrderState.BrokerFilled,
];

export interface OrderDTO {
  order_id: string;
  parent_order_id: string;
  instrument_id: string;
  limit_price: number;
  quantity: number;
  state: OrderState;
  direction: Direction;
  residual: boolean;
  created_by: string;
  account: string;
  tif: string;
  tier: number;
  order_match_request_id: string | null;
  created_at: string;
  updated_at: string;
}

export interface PostOrderPayload {
  parent_order_id: string;
  instrument_id: string;
  limit_price: number;
  quantity: number;
  direction: Direction;
  residual: boolean;
  tier?: number;
}

export interface CreateOrderError {
  input: PostOrderPayload;
  errors: string[];
}

const ordersApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query<OrderDTO, string>({
      query: (id: string) => `/orders/${id}`,
      providesTags: (result, error, arg) => [{ type: "SelectedOrder", id: arg }],
    }),
    // Used to get all orders of a specific instrument (orderbook)
    getOrdersByInstrument: build.query<OrderDTO[], string>({
      query: (instrumentId: string) => `/orders?instrument_id=${instrumentId}&created_by=all`,
      providesTags: (result, error, arg) => [{ type: "Orders", instrumentId: arg }],
    }),
    // Used to get my orders of a specific parent order (middle column in the UI)
    getMyOrders: build.query<OrderDTO[], string>({
      query: (parentOrderId: string) =>
        `/orders?parent_order_id=${parentOrderId}&state=${OrderState.Working},${OrderState.AwaitingMatch},${OrderState.Matched},${OrderState.PartialBrokerAccepted},${OrderState.BrokerAccepted},${OrderState.PartialBrokerFilled},${OrderState.BrokerFilled},${OrderState.Error},${OrderState.AwaitingRfqAcceptance}`,
      providesTags: (result, error, arg) => [{ type: "MyOrders", parentOrderId: arg }],
    }),
    createOrder: build.mutation<OrderDTO, PostOrderPayload>({
      query: (body) => ({
        url: "/orders",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SelectedOrder", "Orders", "SelectedParentOrder", "MyOrders", "MarketData"],
    }),
    deleteOrder: build.mutation<void, string>({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SelectedOrder", "Orders", "MyOrders", "SelectedParentOrder"],
    }),
  }),
});

export const {
  useGetMyOrdersQuery,
  useGetOrderQuery,
  useGetOrdersByInstrumentQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
} = ordersApiSlice;
