import React from "react";

interface TickerCircleProps {
  fillColor: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const TickerCircle: React.FC<TickerCircleProps> = ({ fillColor, width = 8, height = 8, style }) => {
  const defaultStyle = { marginRight: 3, marginBottom: 1 };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style || defaultStyle}
    >
      <circle cx="3.5" cy="4" r="3.5" fill={fillColor} />
    </svg>
  );
};

export default TickerCircle;
