import { themeColors } from "@/app/theme";
import { OrderState } from "@/redux/ordersApiSlice";
import { ParentOrderQuantities } from "@/redux/parentApiSlice";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";

export interface MoreDetailsProps {
  quantities: ParentOrderQuantities;
}

export default function MoreDetails({ quantities }: MoreDetailsProps) {
  const tradeable = quantities.tradable;

  const working = quantities.working;

  const matched = quantities.matched;

  const chipStyles = {
    "fontSize": "10px",
    "fontWeight": "600",
    "height": "19px",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "& .MuiChip-label": {
      padding: "3px 8px",
    },
  };

  return (
    <Stack direction="row" spacing={1}>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Remaining" sx={{ ...chipStyles, backgroundColor: themeColors.white.disabled }} />
        <Typography variant="h5">{numericFormatter(String(tradeable), { thousandSeparator: "," })}</Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Working" sx={{ ...chipStyles, backgroundColor: themeColors.state.newWorking }} />
        <Typography variant="h5">{numericFormatter(String(working), { thousandSeparator: "," })}</Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Matched" sx={{ ...chipStyles, backgroundColor: themeColors.state[OrderState.Matched] }} />
        <Typography variant="h5">{numericFormatter(String(matched), { thousandSeparator: "," })}</Typography>
      </Stack>
    </Stack>
  );
}
