import { themeColors } from "@/app/theme";
import TickerCircle from "@/components/TickerCircle";
import { Stack, Typography } from "@mui/material";

interface BestPriceProps {
  label: string;
  color: string;
  value?: number;
}

const BestPrice: React.FC<BestPriceProps> = ({ label, color, value }) => {
  return (
    <Stack sx={{ width: "100%" }} flexDirection="row" alignItems="center">
      <Typography sx={{ width: 52 }} variant="caption" color={themeColors.black.secondary}>
        {label}:
      </Typography>
      <Typography variant="body" color={color}>
        <TickerCircle style={{ marginBottom: 0, marginRight: 3 }} fillColor={color} />
        {value}
      </Typography>
    </Stack>
  );
};

export default BestPrice;
