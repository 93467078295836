import { themeColors } from "@/app/theme";
import { CompanyDTO, CompanyRole, PostCompanyPayload, useCreateCompanyMutation } from "@/redux/companiesApiSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { default as FormGroup } from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { defaultValues, FormData, schema } from "./schema";

interface Props {
  companies: CompanyDTO[];
  closeModal: () => void;
}

const selectCompanyOptions = (companies: CompanyDTO[]) => {
  return companies.map((company) => ({
    value: company.company_id,
    label: company.name,
  }));
};

const selectPostPayload = ({ risk_desk, ...rest }: FormData): PostCompanyPayload => ({
  ...rest,
  roles: risk_desk ? [CompanyRole.RiskDesk] : [],
  broker_ids: [rest.default_broker],
});

export default function Form({ companies, closeModal }: Props) {
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const options = selectCompanyOptions(companies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [createCompany] = useCreateCompanyMutation();
  const onSubmit = async (formData: FormData) => {
    setApiErrorMessage("");
    const result = await createCompany(selectPostPayload(formData));
    if (result.error) {
      setApiErrorMessage((result.error as any).data.detail);
    } else {
      reset();
      closeModal();
    }
  };
  return (
    <Stack spacing={1} maxWidth={350}>
      <Typography variant="h3">Create a company</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("full_name")}
          margin="normal"
          label="Full name"
          error={!!errors.full_name}
          helperText={errors.full_name?.message}
          size="small"
          autoComplete="full_name"
          autoFocus
          fullWidth
        />
        <TextField
          {...register("name")}
          margin="normal"
          label="Name"
          error={!!errors.name}
          helperText={errors.name?.message}
          size="small"
          autoComplete="name"
          autoFocus
          fullWidth
        />
        <TextField
          {...register("trade_confirmation_email")}
          margin="normal"
          label="Trade confirmation email"
          error={!!errors.trade_confirmation_email}
          helperText={errors.trade_confirmation_email?.message}
          size="small"
          autoComplete="trade_confirmation_email"
          autoFocus
          fullWidth
        />
        <TextField
          select
          fullWidth
          size="small"
          label="Broker"
          {...register("default_broker")}
          value={watch("default_broker")}
          error={!!errors.default_broker}
          helperText={errors.default_broker?.message ?? ""}
          sx={{ mt: 1 }}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Stack sx={{ py: 1 }}>
          <Typography variant="caption">Company roles:</Typography>
          <FormGroup>
            <FormControlLabel
              {...register("risk_desk")}
              control={<Checkbox checked={watch("risk_desk")} />}
              label="Risk desk"
            />
          </FormGroup>
        </Stack>
        {apiErrorMessage && (
          <Typography variant="caption" color={themeColors.error.primary}>
            {apiErrorMessage}
          </Typography>
        )}
        <Stack alignItems={"flex-end"} spacing={2}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            size="small"
            variant="contained"
            color={"rfqDark"}
            sx={{ width: 120 }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
