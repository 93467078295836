import {
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { createSchema, defaultValues, FormFields, FormData } from "./schema";
import LimitPriceInput from "../Summary/LimitPriceInput";
import { NumericFormat } from "react-number-format";
import { Residual } from "../Summary/types";
import { DEFAULT_BROKER } from "@/utils/Constants";
import { Direction } from "@/redux/parentApiSlice";
import { selectParent, setParentOrder } from "@/redux/parentSlice";
import { themeColors } from "@/app/theme";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@/components/FlagIcon";
import BestPrice from "./BestPrice";
import SummaryBoxFooter from "./SummaryBoxFooter";
import { selectInstrument, setInstrument } from "@/redux/instrumentSlice";
import { selectPrice, setPrice } from "@/redux/pricesSlice";
import { useCreateOrderMutation, useGetOrdersByInstrumentQuery } from "@/redux/ordersApiSlice";
import { useRequestMatchMutation } from "@/redux/matchApiSlice";
import { selectAuthUser } from "@/redux/authSlice";
import { useGetMarketDataQuery } from "@/redux/instrumentsApiSlice";

const sellColor = themeColors.prices[Direction.SELL];
const buyColor = themeColors.prices[Direction.BUY];

const NewSummaryBox: React.FC = () => {
  const user = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();
  const selectedParentOrder = useAppSelector(selectParent);
  const selectedInstrument = useAppSelector(selectInstrument);
  const selectedPrice = useAppSelector(selectPrice);
  const [createOrder, { isLoading: isLoadingCreateOrder }] = useCreateOrderMutation();
  const [requestMatch, { isLoading: isLoadingRequestMatch }] = useRequestMatchMutation();
  const { currentData: ordersData } = useGetOrdersByInstrumentQuery(selectedInstrument?.instrument_id || "", {
    skip: !selectedInstrument,
  });
  const { data: marketData } = useGetMarketDataQuery(selectedInstrument?.instrument_id || "", {
    skip: !selectedInstrument,
  });

  const minBlockSize = selectedInstrument?.min_quantity || 0;
  const tradeableTotal = selectedParentOrder?.quantities.tradable;
  const schema = useMemo(() => createSchema(minBlockSize, tradeableTotal), [minBlockSize]);

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = form;
  const { limitPrice, quantity, residual } = watch();

  useEffect(() => {
    if (tradeableTotal !== undefined && tradeableTotal > 0) {
      setValue(FormFields.Quantity, tradeableTotal);
      clearErrors();
    }
  }, [tradeableTotal]);

  useEffect(() => {
    setValue(FormFields.LimitPrice, selectedPrice || null);
    clearErrors();
  }, [selectedPrice]);

  const onSubmit = async (data: FormData) => {
    if (!selectedInstrument || !selectedParentOrder) return;

    const matchedQuantityOrder = ordersData
      ?.filter((order) => order.created_by !== user?.user_id)
      .find((order) => order.quantity === quantity);

    if (!matchedQuantityOrder) {
      if (data.limitPrice !== null) {
        await createOrder({
          parent_order_id: selectedParentOrder.parent_order_id,
          quantity: data.quantity,
          limit_price: data.limitPrice,
          residual: data.residual === Residual.Yes,
          instrument_id: selectedInstrument.instrument_id,
          direction: selectedParentOrder.direction,
        });
      }
    } else {
      await requestMatch({
        quantity,
        requesting_parent_order_id: selectedParentOrder.parent_order_id,
        responding_order_id: matchedQuantityOrder.order_id,
      });
    }

    onDrawerClose();
  };

  const open = !!selectedParentOrder;

  const onDrawerClose = () => {
    dispatch(setParentOrder(null));
    dispatch(setPrice(null));
    dispatch(setInstrument(null));
  };

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          borderLeft: `0.5px solid ${themeColors.border.grey}`,
        },
      }}
      variant="persistent"
      onClose={onDrawerClose}
      open={open}
      anchor="right"
    >
      <Stack
        sx={{
          width: 486,
          padding: "0px 6px 16px 0",
          borderLeft: "none",
          backgroundColor: themeColors.white.primary,
          height: "100%",
          position: "relative",
        }}
        onSubmit={handleSubmit(onSubmit)}
        component="form"
      >
        <Stack
          sx={{
            borderBottom: `1px solid ${themeColors.border.grey}`,
            padding: "8px 0 12px 24px",
            width: "100%",
            backgroundColor: themeColors.white.quaternary,
          }}
          gap={1}
          mb={3}
          flexDirection="row"
          pr={6}
        >
          <Stack spacing={1} flex={1}>
            <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
              <Stack flexDirection="row" alignItems="center" gap={1.2}>
                <FlagIcon height={30} instrumentId={selectedParentOrder?.instrument_id || ""} />
                <Stack>
                  <Typography color={themeColors.black.secondary} variant="h4">
                    {selectedInstrument?.name}
                  </Typography>
                  <Typography variant="bodyLight">{selectedInstrument?.term}</Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack spacing={0.5} flexDirection="column" alignItems="center">
                  <BestPrice color={sellColor} value={marketData?.best_sell_price || 0} label="Best Ask" />
                  <BestPrice color={buyColor} value={marketData?.best_buy_price || 0} label="Best Bid" />
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <IconButton sx={{ position: "relative", bottom: "8px" }} onClick={onDrawerClose}>
            <CloseIcon sx={{ color: themeColors.black.onSurface }} />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            borderRadius: 1,
            border: `0.5px solid ${themeColors.border.grey}`,
            backgroundColor: themeColors.white.primary,
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
            padding: "12px 19px 24px 19px",
            marginLeft: "6px",
            mb: 4,
          }}
          spacing={2}
        >
          <Stack spacing={1.5}>
            <Typography color={themeColors.black.primary} variant="h5Strong">
              Order X: {selectedParentOrder?.direction === Direction.BUY ? "Take" : "Leave"} Liquidity
            </Typography>
            <Stack gap={2} flexDirection="row">
              <NumericFormat
                {...register(FormFields.Quantity)}
                value={quantity}
                customInput={TextField}
                allowNegative={false}
                thousandSeparator
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    setValue(FormFields.Quantity, values.floatValue);
                  } else {
                    setValue(FormFields.Quantity, 0);
                  }
                }}
                size="small"
                label="Quantity"
                sx={{ flex: 1 }}
              />
              <LimitPriceInput
                isActionDisabled={false}
                value={limitPrice}
                setValue={(value) => {
                  setValue(FormFields.LimitPrice, value || 0);
                }}
                inputStyle={{ flex: 1 }}
                disabled={false}
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    setValue(FormFields.LimitPrice, values.floatValue);
                  }
                }}
              />
            </Stack>
            <Stack flexDirection="row" gap={2}>
              <FormControl sx={{ flex: 1 }} size="small">
                <InputLabel id="select-residual-label">Residual</InputLabel>
                <Select
                  {...register(FormFields.Residual)}
                  labelId="select-residual-label"
                  value={residual}
                  label="Residual"
                  MenuProps={{ disablePortal: true }}
                  onChange={(event) => {
                    setValue(FormFields.Residual, event.target.value as Residual);
                  }}
                >
                  <MenuItem value={Residual.No}>No</MenuItem>
                  <MenuItem value={Residual.Yes}>Yes</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ flex: 1 }}
                disabled
                size="small"
                id="broker-field"
                label="Broker"
                value={DEFAULT_BROKER}
              />
            </Stack>
          </Stack>
        </Stack>
        {selectedInstrument && (
          <SummaryBoxFooter
            submitButtonLoading={isLoadingCreateOrder || isLoadingRequestMatch}
            limitPrice={limitPrice}
            total={quantity || 0}
            instrument={selectedInstrument}
            error={errors.quantity?.message || errors.limitPrice?.message || null}
          />
        )}
      </Stack>
    </Drawer>
  );
};

export default NewSummaryBox;
