import React from "react";
import { themeColors } from "@/app/theme";
import FlagIcon from "@/components/FlagIcon";
import { Direction } from "@/redux/parentApiSlice";
import { Stack, Typography } from "@mui/material";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { formatInThousands } from "./helpers";
import TickerCircle from "@/components/TickerCircle";

const sellColor = themeColors.prices[Direction.SELL];
const buyColor = themeColors.prices[Direction.BUY];

interface OrderBookHeaderProps {
  selectedInstrument: InstrumentDTO | null;
  bestBid: number;
  bestAsk: number;
  total: number;
}

const OrderBookHeader: React.FC<OrderBookHeaderProps> = ({ selectedInstrument, bestAsk, bestBid, total }) => {
  return (
    <Stack sx={{ padding: "4px", backgroundColor: themeColors.white.primary }} direction="row" spacing={1}>
      <FlagIcon height={25} width={25} instrumentId={selectedInstrument?.instrument_id || ""} />
      <Stack direction="column">
        <Typography mb={0.25} color={themeColors.logo.primary} variant="h4">
          {selectedInstrument?.name}
        </Typography>
        <Stack spacing={2} direction="row">
          <Typography color={buyColor} variant="body">
            <TickerCircle fillColor={buyColor} />
            {bestBid}
          </Typography>
          <Typography color={sellColor} variant="body">
            <TickerCircle fillColor={sellColor} />
            {bestAsk}
          </Typography>
          <Typography color={themeColors.grey.primary} variant="body">
            {formatInThousands(total)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderBookHeader;
