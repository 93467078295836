import { Stack, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { numericFormatter } from "react-number-format";
import FlagIcon from "@/components/FlagIcon";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { ParentOrderDTO } from "@/redux/parentApiSlice";

interface SmallParentCardProps {
  instrument: InstrumentDTO;
  parentOrderResponse: ParentOrderDTO;
}

const SmallParentCard: React.FC<SmallParentCardProps> = ({ instrument, parentOrderResponse }) => {
  const { quantity, direction, quantities } = parentOrderResponse;
  const { matched, tradable, working } = quantities;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <FlagIcon width={25} height={19} instrumentId={instrument.instrument_id} />
        <Typography variant="h4" sx={{ flex: 1 }}>
          {instrument.name} - {numericFormatter(String(quantity), { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Typography variant="body">Side: {capitalize(direction)}</Typography>
        <Typography variant="body">
          Remaining: {numericFormatter(String(tradable), { thousandSeparator: "," })}
        </Typography>
        <Typography variant="body">Working: {numericFormatter(String(working), { thousandSeparator: "," })}</Typography>
        <Typography variant="body">Matched: {numericFormatter(String(matched), { thousandSeparator: "," })}</Typography>
      </Stack>
    </Stack>
  );
};

export default SmallParentCard;
