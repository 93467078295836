import { themeColors } from "@/app/theme";
import OfferCard from "@/features/OfferCard";
import { getDecimalScale } from "@/features/Summary/LimitPriceInput";
import { selectInstrument } from "@/redux/instrumentSlice";
import { selectActiveRfq } from "@/redux/notificationSlice";
import { OrderDTO } from "@/redux/ordersApiSlice";
import { Direction } from "@/redux/parentApiSlice";
import { selectParent } from "@/redux/parentSlice";
import { Price, resetItems } from "@/redux/pricesSlice";
import { RfqResponseDTO } from "@/redux/rfqApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { BEST_ASK_LABEL, BEST_BID_LABEL, ELECTRONIC_PRICE_LABEL } from "@/translations";
import { metricFormatter } from "@/utils/String";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";

export interface PriceCardProps {
  price: Price;
  orders: (OrderDTO | RfqResponseDTO)[];
  isBest: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  scrollScreenToThis?: boolean;
  onClick: (value: number) => void;
  selectedPrice: number | null;
}

export default function OrderBookTickCard(props: PriceCardProps) {
  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);
  const instrument = useAppSelector(selectInstrument);
  const decimalScale = getDecimalScale(instrument?.tick_size);

  const isSelectable = (!!selectedParent || activeRfq) && !props.price.isElectronic;

  // Discern price card's offer type from the first offer.
  const direction = props.orders.length ? props.orders[0]?.direction : null;

  const isSelected = props.selectedPrice === props.price.value;
  const [isHover, setIsHover] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  // Scroll to the middle of the order book on load
  useEffect(() => {
    // Make sure the active price is not hidden beneath the summary box.
    if (cardRef.current != null && props.scrollScreenToThis) {
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // Scroll to the selected price
  useEffect(() => {
    // Make sure the active price is not hidden beneath the summary box.
    if (cardRef.current !== null && isSelected) {
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSelected]);

  const handleClick = () => {
    if (!isSelectable) return;
    // If selecting an active card, "deselect" by setting zero.
    // Selected Price state is controlled by Prices.tsx
    props.onClick(props.price.value);
    dispatch(resetItems());
  };

  const getBgColour = (isHover = false) => {
    if (!selectedParent && !activeRfq) {
      return themeColors.white.disabled;
    } else
      return isSelected
        ? themeColors.active.secondary
        : isHover && !props.price.isElectronic
        ? themeColors.white.tertiary
        : themeColors.white.primary;
  };

  const ChipDecoration = () => {
    const isBestBidOrAsk = props.isBest && direction;
    if (!isBestBidOrAsk && !props.price.isElectronic) return null;
    return (
      <Chip
        label={
          isBestBidOrAsk
            ? direction === Direction.SELL
              ? BEST_ASK_LABEL.toUpperCase()
              : BEST_BID_LABEL.toUpperCase()
            : ELECTRONIC_PRICE_LABEL
        }
        // label={isBestBidOrAsk ? (Direction === Direction.ASK ? "BEST ASK" : "BEST BID") : ""}
        sx={{
          fontWeight: "bold",
          fontSize: 12,
          color: isBestBidOrAsk ? themeColors.prices[direction] : themeColors.text.disabled,
          backgroundColor: props.price.isElectronic ? getBgColour() : themeColors.white.secondary,
        }}
      />
    );
  };

  return (
    <Paper
      sx={{
        "cursor": !isSelectable ? "default" : "pointer",
        "minHeight": props.orders.length ? 126 : 0,
        "border": 1,
        "borderRadius": 2,
        "borderColor": direction === null ? themeColors.border.grey : themeColors.prices[direction as Direction],
        "py": 1,
        "px": 3,
        "&:hover": {
          backgroundColor: getBgColour(true),
        },
        "backgroundColor": getBgColour(),
      }}
      onClick={() => handleClick()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      ref={cardRef}
    >
      <Stack spacing={1}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }} spacing={2}>
          <Stack direction="row" spacing={2}>
            <NumericFormat
              value={props.price.value}
              allowNegative
              fixedDecimalScale
              decimalScale={decimalScale}
              displayType="text"
              renderText={(value) => (
                <Typography
                  variant="numeric_h4"
                  fontWeight={500}
                  sx={{
                    color: props.price.isElectronic
                      ? themeColors.text.disabled
                      : direction === null
                      ? themeColors.text.primary
                      : themeColors.prices[direction as Direction],
                    textDecoration: isHover && isSelectable ? "underline" : "none",
                  }}
                >
                  {value}
                </Typography>
              )}
            />

            <Typography
              variant="numeric_h4"
              color={props.price.isElectronic ? themeColors.text.disabled : themeColors.text.secondary}
              fontWeight={300}
            >
              {metricFormatter(
                props.orders.map((order) => order.quantity).reduce((acc, cur) => acc + cur, 0),
                1
              )}
            </Typography>
          </Stack>

          <ChipDecoration />
        </Stack>

        {!!props.orders.length && (
          <Stack direction="row" spacing={1}>
            {props.orders.map((item, i) => {
              return <OfferCard key={i} order={item} isPriceSelected={isSelected} onClick={props.onClick} />;
            })}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
