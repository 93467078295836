import { themeColors } from "@/app/theme";
import ProfileMenu from "@/features/Dashboard/ProfileMenu";
import Ticker from "@/features/Dashboard/Ticker";
import NotificationsMenu from "@/features/Notifications/components/NotificationsMenu";
import { selectAuthUser, selectIsUserIntickAdmin } from "@/redux/authSlice";
import { useAppSelector } from "@/store/hooks";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "/logo.svg";
const drawerWidth = 240;

export interface AppBarProps {
  dashboard: boolean;
}

export default function AppBar(props: AppBarProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUserExpanded, setIsUserExpanded] = useState(true);
  const [isAdminExpanded, setIsAdminExpanded] = useState(true);
  const user = useAppSelector(selectAuthUser);
  const isIntickAdmin = useAppSelector(selectIsUserIntickAdmin);

  const handleUserClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsUserExpanded(!isUserExpanded);
  };

  const handleAdminClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsAdminExpanded(!isAdminExpanded);
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ p: 1 }}>
        <img style={{ height: 48 }} src={Logo} alt="InTick Logo" />
      </Box>
      <Divider />
      <List>
        {/* Dashboard */}
        <ListItemButton onClick={() => navigate("/dashboard")}>
          <ListItemIcon>
            <SpaceDashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <ListItemButton onClick={handleAdminClick}>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
          {!isAdminExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={!isAdminExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/billing")}>
              <ListItemIcon>
                <CurrencyPoundIcon />
              </ListItemIcon>
              <ListItemText primary="Billing" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/company")}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Companies panel" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/user")}>
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary="Users panel" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* User */}
        <ListItemButton onClick={handleUserClick}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="User" />
          {!isUserExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/* User Dropdown */}
        <Collapse in={!isUserExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/user/account")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Account Info" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Box>
  );

  const container = window !== undefined ? window.document.body : undefined;

  return (
    <MuiAppBar sx={{ backgroundColor: themeColors.white.primary }} elevation={1}>
      <Toolbar>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent={"space-between"}
          sx={{ width: "100%", height: 109 }}
        >
          {/* Removed the side drawer button at request of James G. / Sarah / Patrick. */}
          {/*  */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isIntickAdmin && (
              <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, height: 48, width: 48 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                cursor: "pointer",
              }}
              onClick={() => navigate("/dashboard")}
            >
              <img style={{ height: 77 }} src={Logo} alt="InTick Logo" />
            </Box>
          </Box>

          <Stack alignItems={"center"} direction="row" spacing={10}>
            {user && props.dashboard && <Ticker />}
            {user && (
              <Box>
                <NotificationsMenu />
                <ProfileMenu />
              </Box>
            )}
          </Stack>
        </Stack>
      </Toolbar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </MuiAppBar>
  );
}
