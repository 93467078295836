import { buildQuery } from "@/config/BaseQuery";
import { createAppSlice } from "@/store/createAppSlice";
import { LocalStorageKeys } from "@/types/LocalStorageKeys";
import { OAuthPostBody } from "@/types/OAuth";
import { PasswordResetPostBody } from "@/types/PasswordReset";
import { UserDTO } from "@/types/User";
import axios, { isAxiosError } from "axios";

export enum UserRole {
  Trader = "trader",
  IntickAdmin = "intick-admin",
  CompanyAdmin = "company-admin",
}

export interface AuthState {
  token: string;
  user: UserDTO | null;
  status: "idle" | "loading" | "failed";
  errorCode: number | null;
  success: boolean | null;
}

const initialState: AuthState = {
  token:
    localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) || sessionStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) || "",
  user: null,
  status: "idle",
  errorCode: null,
  success: null,
};

export const authSlice = createAppSlice({
  name: "auth",
  initialState,
  reducers: (create) => ({
    handleLogin: create.asyncThunk(
      async (payload: OAuthPostBody, { rejectWithValue }) => {
        try {
          const response = await axios.post<string>(buildQuery("/auth/login"), {
            email: payload.email,
            password: payload.password,
          });
          return { token: response.data, rememberMe: payload.rememberMe };
        } catch (error) {
          if (isAxiosError(error)) {
            return rejectWithValue(error.response?.status || null);
          } else {
            return rejectWithValue(null);
          }
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.token = action.payload.token;
          // Does the user want to stay logged in? Then use localStorage.
          // If not, then use sessionStorage.
          if (action.payload.rememberMe) {
            localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, action.payload.token);
          } else {
            sessionStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, action.payload.token);
          }
          state.errorCode = null;
        },
        rejected: (state, action) => {
          state.status = "failed";
          state.errorCode = action.payload as number | null;
        },
      }
    ),
    handlePasswordReset: create.asyncThunk(async (email: string, { rejectWithValue }) => {
      try {
        const response = await axios.post(buildQuery("/users/password-reset"), {
          email,
        });
        return { token: response.data };
      } catch (error) {
        if (isAxiosError(error)) {
          return rejectWithValue(error.response?.status || null);
        } else {
          return rejectWithValue(null);
        }
      }
    }),
    handlePasswordChange: create.asyncThunk(async (payload: PasswordResetPostBody, { rejectWithValue }) => {
      try {
        const response = await axios.post(buildQuery(`/users/password-reset/${payload.hash}`), {
          password: payload.password,
        });
        return { token: response.data };
      } catch (error) {
        if (isAxiosError(error)) {
          return rejectWithValue(error.response?.status || null);
        } else {
          return rejectWithValue(null);
        }
      }
    }),
    handleLogout: create.reducer((state) => {
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      sessionStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      state.token = "";
      state.user = null;
    }),
    fetchUser: create.asyncThunk(
      async (token: string, { rejectWithValue }) => {
        try {
          const response = await axios.get<UserDTO>(buildQuery("/users/me"), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        } catch (error) {
          if (isAxiosError(error)) {
            return rejectWithValue(error.response?.status || null);
          } else {
            return rejectWithValue(null);
          }
        }
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.user = action.payload;
        },
        rejected: (state, action) => {
          state.status = "failed";
          state.errorCode = action.payload as number | null;
        },
      }
    ),
  }),
  selectors: {
    selectAuthToken: (auth) => auth.token,
    selectAuthUser: (auth) => auth.user,
    selectIsUserIntickAdmin: (auth) => {
      if (!auth.user || !auth.user.roles) {
        return false;
      }
      return auth.user.roles.includes(UserRole.IntickAdmin);
    },
    selectAuthStatus: (auth) => auth.status,
    selectCallSuccess: (auth) => auth.success,
    selectAuthErrorCode: (auth) => auth.errorCode,
  },
});

export const { handleLogin, handleLogout, fetchUser, handlePasswordReset, handlePasswordChange } = authSlice.actions;

export const {
  selectIsUserIntickAdmin,
  selectAuthToken,
  selectAuthUser,
  selectAuthStatus,
  selectAuthErrorCode,
  selectCallSuccess,
} = authSlice.selectors;
