import { themeColors } from "@/app/theme";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";

export interface NewAddParentOrderBtnProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

export default function NewAddParentOrderBtn({ onClick, style }: NewAddParentOrderBtnProps) {
  const [hover, setHover] = useState(false);

  return (
    <Button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={style}
      sx={{
        borderRadius: "10px",
        border: `1px solid ${themeColors.text.disabled}`,
        backgroundColor: themeColors.white.secondary,
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        padding: "3px 5px",
      }}
      variant="text"
      onClick={onClick}
    >
      <Stack flexDirection="row" alignItems="center" gap={hover ? 0.75 : 0}>
        <AddCircleOutlineOutlinedIcon />
        <Typography
          color={themeColors.black.primary}
          sx={{
            opacity: hover ? 1 : 0,
            width: hover ? "auto" : 0,
            overflow: "hidden",
            transition: "all 600ms ease-out",
            whiteSpace: "nowrap",
          }}
          variant="h5Strong"
        >
          Add an order
        </Typography>
      </Stack>
    </Button>
  );
}
