import z from "zod";
import { MAX_ORDER_QUANTITY } from "@/utils/Constants";

export const createSchema = (minBlockSize: number) => {
  return z.object({
    direction: z.string().nonempty({ message: "Direction is required" }),
    instrument_id: z.string().nonempty({ message: "Instrument is required" }),
    quantity: z
      .number()
      .min(minBlockSize, { message: `The minimum block size is ${minBlockSize}` })
      .max(MAX_ORDER_QUANTITY, { message: `The maximum block size is ${MAX_ORDER_QUANTITY}` }),
  });
};

export type FormData = z.infer<ReturnType<typeof createSchema>>;

export const defaultValues = {
  direction: "",
  instrument_id: "",
  quantity: undefined,
};
