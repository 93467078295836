import { Stack, Typography, IconButton } from "@mui/material";
import { capitalize } from "lodash";
import { numericFormatter } from "react-number-format";
import FlagIcon from "@/components/FlagIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { themeColors, themeVariants } from "@/app/theme";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { ParentOrderDTO } from "@/redux/parentApiSlice";
import { OrderDTO } from "@/redux/ordersApiSlice";
import MoreDetails from "./MoreDetails";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useToggle from "@/hooks/useToggle";

interface LargeParentCardProps {
  instrument: InstrumentDTO;
  parentOrderResponse: ParentOrderDTO;
  myOrders: OrderDTO[];
  handleCancelOrder: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
  isDisabled: boolean;
}

const LargeParentCard: React.FC<LargeParentCardProps> = ({
  instrument,
  parentOrderResponse,
  myOrders,
  handleCancelOrder,
  isActive,
  isDisabled,
}) => {
  const [workingOrdersOpen, toggleWorkingOrdersOpen] = useToggle(false);

  const { isXl, isMediumOrUp } = useBreakpoint();
  const iconStyles = { color: isActive ? themeColors.white.secondary : themeColors.black.primary };
  const WorkingOrdersIcon = workingOrdersOpen ? RemoveIcon : AddIcon;
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={0.5} direction="row" alignItems="center">
          <FlagIcon width={25} height={19} instrumentId={instrument.instrument_id} />
          <Typography variant="h3" sx={{ flex: 1 }}>
            {instrument.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {isActive && (
            <IconButton
              size="small"
              sx={{
                borderRadius: "6px",
                border: `0.1px solid ${themeColors.white.primary}`,
                padding: "4px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <img src="./send-rfq.svg" />

              <Typography ml={0.5} color={themeColors.white.primary} variant="body">
                {isXl ? "Send " : ""}RFQ
              </Typography>
            </IconButton>
          )}

          <IconButton onClick={handleCancelOrder} sx={{ height: 28, width: 28 }}>
            <ClearIcon
              sx={{
                height: 20,
                width: 20,
                color: isActive || isDisabled ? themeVariants.selected.color : themeVariants.unselected.color,
              }}
            />
          </IconButton>
        </Stack>
      </Stack>

      <Typography pl={0.5} mb={1} variant="captionLight">
        {instrument.term}
      </Typography>

      <Stack spacing={2} justifyContent="space-between" direction="row">
        <Stack sx={{ paddingTop: "3px" }} direction="row">
          <Stack justifyContent="space-between">
            <Typography variant="bodyLight">Side</Typography>
            <Typography fontWeight={600} variant="h5">
              {capitalize(parentOrderResponse.direction)}
            </Typography>
          </Stack>
          <Stack sx={{ ml: isMediumOrUp ? 2 : 1 }} justifyContent="space-between">
            <Typography variant="bodyLight">Quantity</Typography>
            <Typography fontWeight={600} variant="h5">
              {numericFormatter(String(parentOrderResponse.quantity), { thousandSeparator: "," })}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <MoreDetails quantities={parentOrderResponse.quantities} />
        </Stack>
      </Stack>

      {myOrders?.length ? (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: `0.2px solid ${isActive || isDisabled ? themeColors.white.primary : themeColors.black.primary}`,
            paddingTop: "8px",
            marginTop: "8px",
          }}
        >
          <Typography variant="body">Working orders</Typography>
          <WorkingOrdersIcon onClick={toggleWorkingOrdersOpen} sx={iconStyles} />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default LargeParentCard;
