import { themeColors } from "@/app/theme";
import { selectAuthUser } from "@/redux/authSlice";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { selectInstruments, selectTerms } from "@/redux/instrumentSlice";
import { PostMatchRequest, useRequestMatchMutation } from "@/redux/matchApiSlice";
import { selectActiveRfq, setActiveRfq } from "@/redux/notificationSlice";
import { OrderDTO, PostOrderPayload, useCreateOrderMutation } from "@/redux/ordersApiSlice";
import { Direction, ParentOrderDTO } from "@/redux/parentApiSlice";
import { selectParent } from "@/redux/parentSlice";
import { isRfqResponse, resetItems, selectIsEmpty, selectItems, selectPrice, setPrice } from "@/redux/pricesSlice";
import { RfqDTO, RfqResponseDTO, useAcceptRfqMutation, useCreateRfqResponseMutation } from "@/redux/rfqApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { DEFAULT_BROKER } from "@/utils/Constants";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { NumericFormat, numericFormatter } from "react-number-format";
import ErrorLabel from "./ErrorLabel";
import LimitPriceInput from "./LimitPriceInput";
import RfqDetails from "./RfqDetails";
import { Residual } from "./types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createSchema, defaultValues, FormData, FormFields } from "./schema";

const findInstrument = (
  instruments: InstrumentDTO[],
  selectedParent: ParentOrderDTO | null,
  activeRfq: RfqDTO | null
) => {
  return (
    instruments.find(
      (i) => i.instrument_id === selectedParent?.instrument_id || i.instrument_id === activeRfq?.instrument_id
    ) || null
  );
};

const SummaryBox: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedPrice = useAppSelector(selectPrice);
  const instruments = useAppSelector(selectInstruments);
  const selectedItems = useAppSelector(selectItems);
  const selectedParent = useAppSelector(selectParent);
  const isOffersResponseEmpty = useAppSelector(selectIsEmpty);
  const user = useAppSelector(selectAuthUser);
  const activeRfq = useAppSelector(selectActiveRfq);

  const [minBlockSize, setMinBlockSize] = useState(0);
  const [tier, setTier] = useState(99);

  const isPassive = !selectedItems.length;
  const isWarmStart = !!selectedParent && !!selectedPrice;
  const isColdStart = !!selectedParent && isOffersResponseEmpty;
  const isRfq = activeRfq !== null;
  const tradeableTotal = selectedParent?.quantities.tradable;
  const isWorkingOrderSelected =
    selectedItems.length === 1 &&
    !!selectedParent &&
    (selectedItems[0] as OrderDTO).parent_order_id === selectedParent.parent_order_id;

  const [isOpen, setIsOpen] = useState(
    ((isWarmStart || isColdStart || isRfq) && tradeableTotal !== 0) || isWorkingOrderSelected
  );

  const schema = useMemo(() => createSchema(minBlockSize, tradeableTotal), [minBlockSize]);

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    clearErrors,
  } = form;
  const { limitPrice, quantity, residual } = watch();

  useEffect(() => {
    setIsOpen(((isWarmStart || isColdStart || isRfq) && tradeableTotal !== 0) || isWorkingOrderSelected);
    if (isWorkingOrderSelected) {
      setValue(FormFields.Residual, (selectedItems[0] as OrderDTO)?.residual ? Residual.Yes : Residual.No);
    }
  }, [isWarmStart, isColdStart, isRfq, tradeableTotal, selectedItems, selectedParent]);

  // Default for RFQ response.
  useEffect(() => {
    if (!!activeRfq) {
      setValue(FormFields.Residual, Residual.Yes);
    }
  }, [activeRfq]);

  const [createOrder, { isLoading: isLoadingCreateOrder }] = useCreateOrderMutation();

  const [requestMatch, { isLoading: isLoadingRequestMatch }] = useRequestMatchMutation();
  const [acceptRfqResponse] = useAcceptRfqMutation();

  const [createRfqResponse] = useCreateRfqResponseMutation();

  const instrument = findInstrument(instruments, selectedParent, activeRfq);

  useEffect(() => {
    dispatch(resetItems());

    if (instruments) {
      const localInstrument = findInstrument(instruments, selectedParent, activeRfq);

      if (localInstrument) {
        setMinBlockSize(localInstrument.min_quantity);
      }
    }
  }, [selectedParent, activeRfq]);

  useEffect(() => {
    if (!!selectedItems.length) {
      const selectedItemsTotal = selectedItems
        .map((item) => item.quantity)
        .reduce((total, quantity) => total + quantity, 0);

      setValue(FormFields.Quantity, selectedItemsTotal);
      return;
    }

    if (activeRfq !== null) {
      // When an RFQ is active set the summary box quantity to its quantity
      setValue(FormFields.Quantity, activeRfq.quantity);
    } else {
      // When an RFQ is no longer active, reset the summary box quantity
      if (tradeableTotal !== undefined && tradeableTotal > 0) {
        // By default, set the quantity of a passive trade to equal the
        // remaining quantity of a parent.
        setValue(FormFields.Quantity, tradeableTotal);
      } else {
        setValue(FormFields.Quantity, 0);
      }
    }
  }, [selectedItems, activeRfq, tradeableTotal]);

  // If the summary box closes (i.e. by deselecting a price), the default
  // tier should be reset to 1.
  // Likewise isResidual should be reset to "No" (if not responding to an RFQ).
  useEffect(() => {
    if (!isOpen) {
      setTier(99);
      setValue(FormFields.Residual, isRfq ? Residual.Yes : Residual.No);
    }
  }, [isOpen]);

  useEffect(() => {
    setValue(FormFields.LimitPrice, selectedPrice || null);
    clearErrors();
  }, [selectedPrice]);

  const onSubmit = handleSubmit(async (data) => {
    if (isRfq) {
      handleSubmitRfq();
      return;
    }
    if (!selectedParent || !selectedPrice || !instrument) return;
    if (!selectedItems.length) {
      const direction = activeRfq ? (activeRfq as RfqDTO).direction : selectedParent.direction;
      // Create a passive offer (a.k.a. a "working" order)
      const payload: PostOrderPayload = {
        parent_order_id: selectedParent.parent_order_id,
        quantity: data.quantity,
        limit_price: data.limitPrice || 0,
        residual: data.residual === Residual.Yes,
        instrument_id: instrument.instrument_id,
        direction,
        tier,
      };
      try {
        await createOrder(payload).unwrap();
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (passive) Error: ", error);
      }
    } else {
      // Aggresively take one or more offers (a.k.a. create "waiting" orders)
      const payloads = selectedItems.map((item) =>
        isRfqResponse(item)
          ? {
              isRfqResponse: true,
              rfq_id: (item as RfqResponseDTO).rfq_id,
              rfq_response_id: (item as RfqResponseDTO).rfq_response_id,
            }
          : ({
              isRfqResponse: false,
              requesting_parent_order_id: selectedParent.parent_order_id,
              responding_order_id: (item as OrderDTO).order_id,
              quantity: (item as OrderDTO).quantity,
            } as PostMatchRequest)
      );
      try {
        const promises = payloads.map(({ isRfqResponse, rfq_id, rfq_response_id, ...rest }: any) =>
          isRfqResponse ? acceptRfqResponse({ rfq_id, rfq_response_id }) : requestMatch(rest)
        );
        await Promise.all(promises);
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (aggresive) Error: ", error);
      } finally {
        resetGlobalPrice(); // Deselect the price.
        dispatch(resetItems()); // Deselect all offers.
      }
    }
  });

  const handleSubmitRfq = async () => {
    if (!isRfq || !user || selectedPrice === null) return;
    try {
      const payload = {
        rfq_id: activeRfq.rfq_id,
        // residual: isResidual === Residual.Yes,
        quantity,
        direction: selectedParent?.direction ?? activeRfq?.direction,
        limit_price: selectedPrice,
      };
      await createRfqResponse(payload).unwrap();
    } catch (error) {
      console.log("[ SummaryBox ] createRfqResponse error", error);
    } finally {
      resetGlobalPrice();
      dispatch(setActiveRfq(null));
    }
  };

  useEffect(() => {
    // Update the redux store with the limit price - this enables price ladder
    // updates if there are no offers in the market.
    if (isValid) {
      dispatch(setPrice(limitPrice));
    }
  }, [limitPrice]);

  const resetGlobalPrice = () => {
    dispatch(setPrice(null));
  };

  const getButtonColour = () => {
    if (activeRfq && activeRfq?.direction) {
      return activeRfq.direction === Direction.BUY ? Direction.SELL : Direction.BUY;
    }
    if (selectedParent?.direction === undefined) return "dark";
    return selectedParent?.direction;
  };

  const activeUnderlying = instrument?.name ?? "";
  const activeDirection = selectedParent?.direction ?? activeRfq?.direction ?? "";

  const activeQuantity = numericFormatter(String(quantity), {
    thousandSeparator: true,
  });

  const getActiveDirection = () => {
    if (activeRfq && activeRfq?.direction) {
      return activeRfq.direction === Direction.BUY ? Direction.SELL : Direction.BUY;
    }
    if (selectedParent && selectedParent.direction) {
      return selectedParent.direction;
    }
    return "";
  };

  const selectSubmitButtonLabel = () => {
    if (getActiveDirection() !== "") {
      return capitalize(getActiveDirection());
    }
    return "Submit";
  };

  const [termStart, termEnd] = selectTerms(instrument);
  const remaining = Number(selectedParent?.quantities.tradable) - quantity;
  const remainderWarning =
    instrument !== null && remaining > 0 && remaining < instrument.min_quantity
      ? "This trade leaves a remainder below the block size!"
      : null;

  if (isOpen && !!user) {
    return (
      <Paper
        elevation={4}
        component="form"
        onSubmit={onSubmit}
        sx={{
          zIndex: 1000,
          minWidth: !!activeRfq ? "60vw" : "50vw",
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: themeColors.white.primary,
          borderTop: `1px solid ${themeColors.border.grey}`,
          borderRadius: "8px 0 0 8px",
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ pr: 3 }}>
          <Stack direction="row">
            {!!activeRfq && <RfqDetails />}
            <Stack sx={{ py: 4, pl: 2 }}>
              <Typography variant="h6" lineHeight={"130%"}>
                {activeUnderlying}
              </Typography>
              <Typography variant={"body1"} lineHeight={"130%"}>
                {instrument?.term}
              </Typography>
              {activeDirection !== "" && (
                <Typography minWidth={150} variant="captionLight" mt={1}>
                  {capitalize(getActiveDirection())} {activeQuantity} {activeUnderlying} {termStart} <br />
                  {capitalize(Object.values(Direction).find((value) => value !== getActiveDirection()))}{" "}
                  {activeQuantity} {activeUnderlying} {termEnd}
                </Typography>
              )}
            </Stack>
          </Stack>
          <IconButton sx={{ position: "absolute", top: 0, right: 0 }} onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>

          <Stack spacing={2} alignItems="flex-end" py={5}>
            {/* Top row */}
            <Stack direction="row" height={40} spacing={1}>
              <NumericFormat
                {...register(FormFields.Quantity)}
                value={quantity}
                customInput={TextField}
                allowNegative={false}
                thousandSeparator
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    setValue(FormFields.Quantity, values.floatValue);
                  } else {
                    setValue(FormFields.Quantity, 0);
                  }
                }}
                sx={{ width: 128 }}
                disabled={selectedItems?.length !== 0}
                size="small"
                label="Quantity"
              />
              <LimitPriceInput
                inputStyle={{ width: 180 }}
                value={limitPrice}
                setValue={(value) => {
                  setValue(FormFields.LimitPrice, value || 0);
                }}
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    if (!isColdStart && !isRfq) {
                      setValue(FormFields.LimitPrice, values.floatValue);
                    } else {
                      setValue(FormFields.LimitPrice, values.floatValue);
                    }
                  }
                }}
                disabled={!!selectedItems.length}
                isActionDisabled={!instruments || !!selectedItems.length}
              />
              {(isPassive || isWorkingOrderSelected) && !activeRfq && (
                <FormControl size="small" sx={{ width: 96 }}>
                  <InputLabel id="select-residual-label">Residual</InputLabel>
                  <Select
                    {...register(FormFields.Residual)}
                    labelId="select-residual-label"
                    id="select-residual"
                    value={residual}
                    label="Residual"
                    disabled={isWorkingOrderSelected}
                    MenuProps={{ disablePortal: true }}
                    onChange={(event) => {
                      setValue(FormFields.Residual, event.target.value as Residual);
                    }}
                  >
                    <MenuItem value={Residual.No}>No</MenuItem>
                    <MenuItem value={Residual.Yes}>Yes</MenuItem>
                  </Select>
                </FormControl>
              )}
              {!isWorkingOrderSelected && (
                <Badge
                  badgeContent={selectedItems.length}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="success"
                >
                  <LoadingButton
                    loading={isLoadingCreateOrder || isLoadingRequestMatch}
                    size="small"
                    variant="contained"
                    color={getButtonColour()}
                    sx={{ fontSize: 18, borderRadius: 2 }}
                    data-testid="submit-order"
                    type="submit"
                    disabled={limitPrice === null}
                  >
                    <span>{selectSubmitButtonLabel()}</span>
                  </LoadingButton>
                </Badge>
              )}
            </Stack>

            {/* Bottom Row */}
            {!isRfq && (
              <Stack direction="row" height={40} spacing={1}>
                {(isPassive || isWorkingOrderSelected) && (
                  <>
                    <TextField
                      sx={{ width: 128 }}
                      disabled
                      size="small"
                      id="broker-field"
                      label="Broker"
                      value={DEFAULT_BROKER}
                      // value={user["default-broker"]}
                    />
                    <TextField sx={{ width: 96 }} disabled size="small" id="tif-field" label="TIF" value="Day" />
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <ErrorLabel message={errors.quantity?.message || errors.limitPrice?.message || remainderWarning || null} />
      </Paper>
    );
  }

  return null;
};

export default SummaryBox;
