import { themeColors } from "@/app/theme";
import { Direction } from "@/redux/parentApiSlice";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { formatInThousands, calculatePercentage } from "./helpers";

interface HorizontalBarChartProps {
  value: number;
  chipValue: number;
  maxOrdersValue: number;
  variant: Direction;
}

const stylesByVariant = {
  [Direction.SELL]: {
    boxShadowColor: "#D6817D",
    progressBarColor: "#E05E57",
    background: "linear-gradient(135deg,#F7F1F1 0%,#F5E9E9 100%)",
  },
  [Direction.BUY]: {
    boxShadowColor: "#7B7B9C",
    progressBarColor: "#3D419C",
    background: "linear-gradient(91deg, #F9F9FD 4.83%, #E9E9F4 96.23%)",
  },
};

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ value, variant, maxOrdersValue, chipValue }) => {
  const isVariantBuy = variant === Direction.BUY;
  const styles = stylesByVariant[variant];
  const percentage = calculatePercentage(value, maxOrdersValue);

  const QuantityText = () => (
    <Typography
      sx={{
        minWidth: 46,
        textAlign: isVariantBuy ? "right" : "left",
        fontWeight: "600",
        lineHeight: "140%",
        letterSpacing: "-0.3px",
        fontSize: "15px",
      }}
      color={themeColors.black.primary}
    >
      {value ? formatInThousands(value) : null}
    </Typography>
  );

  return (
    <Stack className="horizontal-bar-stack" direction={isVariantBuy ? "row" : "row-reverse"} alignItems="center">
      <QuantityText />
      <Box
        sx={{ position: "relative", marginRight: isVariantBuy ? "0" : "2px", marginLeft: isVariantBuy ? "2px" : "0" }}
        width={108}
      >
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            "height": 17,
            "borderRadius": "0px 44px 44px 0px",
            "background": styles.background,
            "transform": isVariantBuy ? "scaleX(-1)" : null,

            "& .MuiLinearProgress-bar": {
              background: styles.progressBarColor,
              borderRadius: "0px 44px 44px 0px",
              boxShadow: `5px -4px 10px 0px ${styles.boxShadowColor} inset`,
            },
          }}
        />
        {chipValue > 0 ? (
          <Stack
            justifyContent="center"
            sx={{
              position: "absolute",
              top: "0",
              left: isVariantBuy ? "auto" : "0px",
              right: isVariantBuy ? "0" : "auto",
              background: "rgba(195, 230, 237, 0.90)",
              borderRadius: "4px 0 0 4px",
              height: "100%",
            }}
          >
            <Typography variant="caption" color={themeColors.black.secondary}>
              {formatInThousands(chipValue)}
            </Typography>
          </Stack>
        ) : null}
      </Box>
    </Stack>
  );
};

export default HorizontalBarChart;
