import { themeColors } from "@/app/theme";
import { UserRole } from "@/redux/authSlice";
import { CompanyDTO } from "@/redux/companiesApiSlice";
import { PostUserPayload, useCreateUserMutation } from "@/redux/usersApiSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { default as FormGroup } from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { defaultValues, FormData, schema } from "./schema";

interface Props {
  companies: CompanyDTO[];
  closeModal: () => void;
}

const selectCompanyOptions = (companies: CompanyDTO[]) => {
  return companies.map((company) => ({
    value: company.company_id,
    label: company.name,
  }));
};

const selectRoleForAPI = (trader_role: boolean, company_admin_role: boolean, intick_admin_role: boolean) => {
  const roles = [];
  if (trader_role) {
    roles.push(UserRole.Trader);
  }
  if (company_admin_role) {
    roles.push(UserRole.CompanyAdmin);
  }
  if (intick_admin_role) {
    roles.push(UserRole.IntickAdmin);
  }
  return roles;
};

const selectPostUserPayload = ({
  trader_role,
  intick_admin_role,
  company_admin_role,
  ...rest
}: FormData): PostUserPayload => ({
  roles: selectRoleForAPI(trader_role, company_admin_role, intick_admin_role),
  ...rest,
});

export default function Form({ companies, closeModal }: Props) {
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const options = selectCompanyOptions(companies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [createUser] = useCreateUserMutation();
  const onSubmit = async (formData: FormData) => {
    setApiErrorMessage("");
    const result = await createUser(selectPostUserPayload(formData));
    if (result.error) {
      setApiErrorMessage((result.error as any).data.detail);
    } else {
      reset();
      closeModal();
    }
  };

  return (
    <Stack spacing={1} maxWidth={350}>
      <Typography variant="h3">Create a user</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("first_name")}
          margin="normal"
          label="First name"
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
          size="small"
          autoComplete="first_name"
          autoFocus
          fullWidth
        />
        <TextField
          {...register("last_name")}
          margin="normal"
          label="Last name"
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
          size="small"
          autoComplete="last_name"
          autoFocus
          fullWidth
        />
        <TextField
          {...register("email")}
          margin="normal"
          label="Email"
          error={!!errors.email}
          helperText={errors.email?.message}
          size="small"
          autoComplete="email"
          autoFocus
          fullWidth
        />
        <TextField
          select
          fullWidth
          size="small"
          label="Company"
          {...register("company_id")}
          value={watch("company_id")}
          error={!!errors.company_id}
          helperText={errors.company_id?.message ?? ""}
          sx={{ mt: 1 }}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Stack sx={{ py: 1 }}>
          <Typography variant="caption">User roles:</Typography>
          <FormGroup>
            <FormControlLabel
              {...register("trader_role")}
              control={<Checkbox checked={watch("trader_role")} />}
              label="Trader role"
            />
            <FormControlLabel
              {...register("company_admin_role")}
              control={<Checkbox checked={watch("company_admin_role")} />}
              label="Company admin role"
            />
            <FormControlLabel
              {...register("intick_admin_role")}
              control={<Checkbox checked={watch("intick_admin_role")} />}
              label="Intick admin role"
            />
          </FormGroup>
        </Stack>
        <FormGroup>
          <Typography variant="caption">Should user be activated:</Typography>
          <FormControlLabel {...register("active")} control={<Checkbox defaultChecked />} label="Active" />
        </FormGroup>
        {apiErrorMessage && (
          <Typography variant="caption" color={themeColors.error.primary}>
            {apiErrorMessage}
          </Typography>
        )}
        <Stack alignItems={"flex-end"} spacing={2}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            size="small"
            variant="contained"
            color={"rfqDark"}
            sx={{ width: 120 }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
