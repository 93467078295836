import { baseApi } from "@/config/BaseQuery";

export interface InstrumentDTO {
  instrument_id: string;
  name: string;
  tick_size: number;
  min_quantity: number;
  description: string;
  exchange: string;
  country: string;
  term: string;
  created_at: string;
  updated_at: string;
}

export interface MarketDataDTO {
  best_sell_price: number | null;
  best_buy_price: number | null;
  instrument_id: string;
  total_buy_quantity: number;
  total_sell_quantity: number;
}

const instrumentsApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInstruments: build.query<InstrumentDTO[], void>({
      query: () => "/instruments",
      keepUnusedDataFor: 900, // 15 mins.
      providesTags: [{ type: "Instruments", id: "LIST" }],
    }),
    getMarketData: build.query<MarketDataDTO, string>({
      query: (instrumentId) => `/instruments/${instrumentId}/market-data`,
      providesTags: ["MarketData"],
    }),
  }),
});

export const { useGetInstrumentsQuery, useGetMarketDataQuery } = instrumentsApiSlice;
