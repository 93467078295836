import { Stack } from "@mui/material";
import React, { useState } from "react";
import NewParentOrderCard from "../NewParentOrderCard";
import { ParentOrderDTO } from "@/redux/parentApiSlice";
import NewAddParentOrderBtn from "./NewAddParentOrderBtn";
import CreateOrderModal from "../ParentOrdersList/CreateOrderModal";

interface NewParentOrdersListProps {
  parentOrders?: ParentOrderDTO[];
  moveOrderbookToStart: (instrumentId: string) => void;
}

const NewParentOrdersList: React.FC<NewParentOrdersListProps> = ({ parentOrders, moveOrderbookToStart }) => {
  const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false);

  return (
    <Stack flex={1} spacing={2}>
      <Stack sx={{ overflowY: "auto", maxHeight: 470 }} spacing={1} flexDirection="column">
        {parentOrders?.map((order) => (
          <NewParentOrderCard moveOrderbookToStart={moveOrderbookToStart} order={order} />
        ))}
      </Stack>
      <NewAddParentOrderBtn
        style={{ alignSelf: "flex-start", minWidth: "auto", marginLeft: "10px" }}
        onClick={() => setCreateOrderModalOpen(true)}
      />
      {createOrderModalOpen && <CreateOrderModal open={createOrderModalOpen} setOpen={setCreateOrderModalOpen} />}
    </Stack>
  );
};

export default NewParentOrdersList;
