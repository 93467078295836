import z from "zod";
import { Residual } from "../Summary/types";

export enum FormFields {
  Quantity = "quantity",
  LimitPrice = "limitPrice",
  Residual = "residual",
}
export const createSchema = (minBlockSize: number, tradeableTotal?: number) => {
  return z.object({
    [FormFields.Quantity]: z
      .number()
      .min(minBlockSize, { message: `Please enter an amount greater than the minimum block size of ${minBlockSize}` })
      .refine((val) => !tradeableTotal || val <= tradeableTotal, {
        message: "Quantity exceeds the remaining quantity on the order.",
      }),
    [FormFields.LimitPrice]: z.number().nullable(),
    [FormFields.Residual]: z.nativeEnum(Residual),
  });
};

export const defaultValues = {
  [FormFields.Quantity]: undefined,
  [FormFields.LimitPrice]: null,
  [FormFields.Residual]: Residual.No,
};

export type FormData = z.infer<ReturnType<typeof createSchema>>;
