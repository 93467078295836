import { handleLogout } from "@/redux/authSlice";
import { resetState } from "@/store/reset";
import { RootState } from "@/store/store";
import { type BaseQueryFn, type FetchArgs, type FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { handleError, handleSuccess } from "./errorSlice";

export const baseUrl = `${import.meta.env["VITE_API_URL"]}/api`;

/**
 * @param endpoint Include the preceeding `"/"`, e.g. `"/users"`
 * @returns `https://intick-uat-api.azure-api.net/api` + `endpoint`
 */
export function buildQuery(endpoint: string) {
  return `${baseUrl}${endpoint}`;
}

const authorizedQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await authorizedQuery(args, api, extraOptions);
  if (result.error && typeof result.error.status === "number") {
    // Store the error status so we can present something readable to the user.
    api.dispatch(handleError({ status: result.error.status }));

    if (result.error.status === 401) {
      // Automatically logout users upon receiving a 401 error.
      api.dispatch(handleLogout());
      api.dispatch(resetState);
      api.dispatch(baseApi.util.resetApiState());
    }
  } else if (!result.error) {
    api.dispatch(handleSuccess());
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: [
    "Env",
    "Instruments",
    "Orders",
    "ParentOrders",
    "SelectedParentOrder",
    "SelectedOrder",
    "Companies",
    "Users",
    "MyOrders",
    "MatchRequestNotifications",
    "Rfqs",
    "RfqResponses",
    "RiskDeskBrokers",
    "MarketData",
  ],
  endpoints: () => ({}),
});
