import AppBar from "@/app/router/components/AppBar";
import { useGetCompaniesQuery } from "@/redux/companiesApiSlice";
import { useGetUsersQuery } from "@/redux/usersApiSlice";
import Stack from "@mui/material/Stack";
import CreateUserModal from "./CreateUserModal";
import UsersTable from "./UsersTable";
import { selectIsUserIntickAdmin } from "@/redux/authSlice";
import { useAppSelector } from "@/store/hooks";

export default function CreateCompany() {
  const { data: companiesData } = useGetCompaniesQuery();
  const { data: usersData } = useGetUsersQuery();
  const isIntickAdmin = useAppSelector(selectIsUserIntickAdmin);

  if (!isIntickAdmin) {
    return null;
  }

  return (
    <Stack spacing={13} width="100%" alignItems="center" flexDirection="column">
      <AppBar dashboard={false} />
      <Stack sx={{ py: 3, px: 3 }} width="100%" alignItems="center" spacing={3}>
        {companiesData && <CreateUserModal data={companiesData} />}
        {usersData && <UsersTable data={usersData} />}
      </Stack>
    </Stack>
  );
}
